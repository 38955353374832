import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import axiosInstance from '@/axios'

class ChatAPIModel {
  createChannel(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL.replace('/api', '')}/chatify/api/Channel/create`,
          data,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getFarmer(id: number, withs: string[] = []): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.Farmer,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getCustomer(id: number, withs: string[] = []): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.getItem(
        Microservices.Core,
        APIModels.Customer,
        id,
      )
        .withs(withs)
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async getHasUnreadChannels(userUid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return axiosInstance
        .get(
          `${process.env.VUE_APP_BASE_URL.replace('/api', '')}/chatify/api/User/getHasUnreadChannels?user_uuid=${userUid}`,
        )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

const ChatAPI = new ChatAPIModel()
export { ChatAPI }
